import ReactDOM from 'react-dom/client';
import '@alluvial/ui-components/bundle.scss';
import '@rainbow-me/rainbowkit/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AlluvialProvider } from '@alluvial/ui-components/components';
import { config } from './wagmi';
import App from './App';
import * as Sentry from '@sentry/react';
import { initGTM } from './gtm'; // Import the GTM initialization function

const queryClient = new QueryClient();

const sentryDSN = import.meta.env.VITE_REACT_APP_SENTRY_DSN;
const sentryEnv = import.meta.env.VITE_REACT_APP_SENTRY_ENVIRONMENT;

Sentry.init({
  dsn: sentryDSN,
  integrations: [],
  environment: sentryEnv || 'development',
});

initGTM(); // Initialize Google Tag Manager

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
    <QueryClientProvider client={queryClient}>
      <AlluvialProvider
        config={config as never}
        changeNetworkTitle={'Change Network'}
        changeNetworkDescription={
          'Please change the network from your wallet in order to continue using the application'
        }>
        <App />
      </AlluvialProvider>
    </QueryClientProvider>
  </Sentry.ErrorBoundary>
);
