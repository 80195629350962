import {
  REWARDS_PAGE_TRANSLATIONS_KEY,
  GENERIC_TRANSLATIONS_KEY,
  STAKE_PAGE_TRANSLATIONS_KEY,
  menu,
  AppRoutes,
} from '../constants.ts';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  Stake,
  MobileAccordionMetrics,
  FAQ,
  FAQItems,
  Decimal,
} from '@alluvial/ui-components/components';

export const StakePage = () => {
  const { t } = useTranslation();

  return (
    <Layout
      routes={AppRoutes}
      ethStakedLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.ethStakedLabel`)}
      lsEthSupplyLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.lsEthSupplyLabel`
      )}
      menuTooltipContentLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.menu.menuTooltipContentLabel`
      )}
      menuTooltipLinkLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.menu.menuTooltipLinkLabel`
      )}
      conversionRateTooltip={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateTooltip`
      )}
      conversionRateLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateLabel`
      )}
      averageTooltip={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageTooltip`)}
      averageLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageLabel`)}
      liquidCollectiveTooltip={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveTooltip`
      )}
      liquidCollectiveLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveLabel`
      )}
      lastOracleRunLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.lastOracleRunLabel`
      )}
      ethSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
      lsEthSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
      liquidCollectiveSidebarLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.sidebar.liquidCollectiveSidebarLabel`
      )}
      etherscanLabel={t(`${GENERIC_TRANSLATIONS_KEY}.sidebar.etherscanLabel`)}
      duneLabel={t(`${GENERIC_TRANSLATIONS_KEY}.sidebar.duneLabel`)}
      protocolMetricsLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.sidebar.protocolMetricsLabel`
      )}
      items={menu.map((routeInfo) => ({
        label: t(`${GENERIC_TRANSLATIONS_KEY}.${routeInfo.label}`),
        route: routeInfo.route,
      }))}
      walletBalanceLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.accountInfo.walletBalanceLabel`
      )}
      stakedBalanceLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.accountInfo.stakedBalanceLabel`
      )}
      disconnectButtonLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.accountInfo.disconnectButtonLabel`
      )}>
      <Stake
        ethSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
        lsEthSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
        headline={t(`${STAKE_PAGE_TRANSLATIONS_KEY}.headline`)}
        stakeCaption={t(`${STAKE_PAGE_TRANSLATIONS_KEY}.stakeCaption`)}
        trailingRateTooltip={t(
          `${STAKE_PAGE_TRANSLATIONS_KEY}.trailingRateTooltip`
        )}
        trailingRateCaption={t(
          `${STAKE_PAGE_TRANSLATIONS_KEY}.trailingRateCaption`
        )}
        toastNotificationLabel={t(
          `${STAKE_PAGE_TRANSLATIONS_KEY}.toastNotificationLabel`
        )}
        incorrectValue={t(
          `${GENERIC_TRANSLATIONS_KEY}.operationInfo.errors.incorrectValue`
        )}
        insufficientTokens={t(
          `${GENERIC_TRANSLATIONS_KEY}.operationInfo.errors.insufficientTokens`
        )}
        maximumValue={t(
          `${GENERIC_TRANSLATIONS_KEY}.operationInfo.errors.maximumValue`
        )}
        minimumValue={t(
          `${GENERIC_TRANSLATIONS_KEY}.operationInfo.errors.minimumValue`
        )}
        maxDecimals={t(
          `${GENERIC_TRANSLATIONS_KEY}.operationInfo.errors.maxDecimals`,
          {
            decimal: Decimal,
          }
        )}
        availableCaption={t(
          `${GENERIC_TRANSLATIONS_KEY}.operationInfo.availableCaption`
        )}
        addressCaption={t(
          `${GENERIC_TRANSLATIONS_KEY}.operationInfo.addressCaption`
        )}
        maxButtonLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.operationInfo.maxButtonLabel`
        )}
        receiveCaption={t(
          `${GENERIC_TRANSLATIONS_KEY}.operationInfo.receiveCaption`
        )}
        nativeConversionTooltip={t(
          `${STAKE_PAGE_TRANSLATIONS_KEY}.commonRows.nativeConversionTooltip`
        )}
        nativeConversionCaption={t(
          `${STAKE_PAGE_TRANSLATIONS_KEY}.commonRows.nativeConversionCaption`
        )}
        liquidCollectiveTooltip={t(
          `${STAKE_PAGE_TRANSLATIONS_KEY}.commonRows.liquidCollectiveTooltip`
        )}
        liquidCollectiveCaption={t(
          `${STAKE_PAGE_TRANSLATIONS_KEY}.commonRows.liquidCollectiveCaption`
        )}
        maximumCostTooltip={t(
          `${STAKE_PAGE_TRANSLATIONS_KEY}.commonRows.maximumCostTooltip`
        )}
        maximumCostCaption={t(
          `${STAKE_PAGE_TRANSLATIONS_KEY}.commonRows.maximumCostCaption`
        )}
        reviewStakeButtonLabel={t(
          `${STAKE_PAGE_TRANSLATIONS_KEY}.reviewStakeButtonLabel`
        )}
        enterAmountButtonLabel={t(
          `${STAKE_PAGE_TRANSLATIONS_KEY}.enterAmountButtonLabel`
        )}
        reviewStakeHeadline={t(
          `${STAKE_PAGE_TRANSLATIONS_KEY}.reviewStakeHeadline`
        )}
        contractAddressLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.contractAddressLabel`
        )}
        contractAddressTooltip={t(
          `${GENERIC_TRANSLATIONS_KEY}.contractAddressTooltip`
        )}
        receiveLabel={t(`${STAKE_PAGE_TRANSLATIONS_KEY}.receiveLabel`)}
        signTransactionInWalletButtonLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.signButtons.signTransactionInWalletButtonLabel`
        )}
        signTransactionButtonLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.signButtons.signTransactionButtonLabel`
        )}
        cancelButtonLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.signButtons.cancelButtonLabel`
        )}
        globalErrorLabel={t(`${GENERIC_TRANSLATIONS_KEY}.globalErrorLabel`)}
      />
      <MobileAccordionMetrics
        caption={t(`${REWARDS_PAGE_TRANSLATIONS_KEY}.accordionMetrics.caption`)}
        headline={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.accordionMetrics.headline`
        )}
        ethStakedLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.ethStakedLabel`)}
        lsEthSupplyLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.lsEthSupplyLabel`
        )}
        conversionRateTooltip={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateTooltip`
        )}
        conversionRateLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateLabel`
        )}
        averageTooltip={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageTooltip`)}
        averageLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageLabel`)}
        liquidCollectiveTooltip={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveTooltip`
        )}
        liquidCollectiveLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveLabel`
        )}
        lastOracleRunLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.lastOracleRunLabel`
        )}
        ethSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
        lsEthSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
      />
      <FAQ
        label={t(`${GENERIC_TRANSLATIONS_KEY}.faq.label`)}
        items={[
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.WHAT_IS_LSETH.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.WHAT_IS_LSETH.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.PROTOCOL_RATE.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.PROTOCOL_RATE.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.PROTOCOL_SERVICE_FEE.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.PROTOCOL_SERVICE_FEE.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.VALUE_DIFFERENCE.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.VALUE_DIFFERENCE.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.LIQUID_COLLECTIVE.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.LIQUID_COLLECTIVE.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.WITHDRAWAL_TIME.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.WITHDRAWAL_TIME.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.HOW_REDEMPTIONS_WORK.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.HOW_REDEMPTIONS_WORK.content}`
            ),
          },
        ]}
      />
    </Layout>
  );
};
