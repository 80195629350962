import {
  AppRoutesType,
  FAQItemsType,
} from '@alluvial/ui-components/components';

export const AppRoutes: AppRoutesType = {
  home: '/',
  rewards: '/rewards',
  stake: '/stake',
  redeem: '/redeem',
  redeemPending: '/redeem-pending',
  transactions: '/transactions',
};

export const termsAndServiceRoute = '/terms-of-service';

export const menu = [
  { label: 'routesLabels.rewards', route: AppRoutes.rewards },
  { label: 'routesLabels.stake', route: AppRoutes.stake },
  { label: 'routesLabels.redeem', route: AppRoutes.redeem },
  { label: 'routesLabels.pending', route: AppRoutes.redeemPending },
  { label: 'routesLabels.transactions', route: AppRoutes.transactions },
];

export const GENERIC_TRANSLATIONS_KEY = 'generic';
export const REWARDS_PAGE_TRANSLATIONS_KEY = 'rewardsPage';
export const STAKE_PAGE_TRANSLATIONS_KEY = 'stakePage';
export const REDEEM_PAGE_TRANSLATIONS_KEY = 'redeemPage';
export const PENDING_REDEEM_PAGE_TRANSLATIONS_KEY = 'pendingRedeemPage';
export const TRANSACTION_PAGE_TRANSLATIONS_KEY = 'transactionsPage';

export const FAQItems: FAQItemsType = {
  WHAT_IS_LSETH: {
    title: 'whatIsLsEth.title',
    content: 'whatIsLsEth.content',
  },
  PROTOCOL_RATE: {
    title: 'protocolRate.title',
    content: 'protocolRate.content',
  },
  PROTOCOL_SERVICE_FEE: {
    title: 'protocolServiceFee.title',
    content: 'protocolServiceFee.content',
  },
  VALUE_DIFFERENCE: {
    title: 'valueDifference.title',
    content: 'valueDifference.content',
  },
  LIQUID_COLLECTIVE: {
    title: 'liquidCollective.title',
    content: 'liquidCollective.content',
  },
  WITHDRAWAL_TIME: {
    title: 'withdrawalTime.title',
    content: 'withdrawalTime.content',
  },
  HOW_REDEMPTIONS_WORK: {
    title: 'howRedemptionsWork.title',
    content: 'howRedemptionsWork.content',
  },
};
