import { useTranslation } from 'react-i18next';
import {
  AppRoutes,
  FAQItems,
  GENERIC_TRANSLATIONS_KEY,
  menu,
  REWARDS_PAGE_TRANSLATIONS_KEY,
} from '../constants.ts';
import {
  Layout,
  MobileAccordionMetrics,
  RewardsChart,
  FAQ,
  RewardsTransactions,
  WalletCardGroup,
  UserCardTypes,
} from '@alluvial/ui-components/components';

export const RewardsPage = () => {
  const { t } = useTranslation();

  return (
    <Layout
      routes={AppRoutes}
      isAllowed={true}
      ethStakedLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.ethStakedLabel`)}
      lsEthSupplyLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.lsEthSupplyLabel`
      )}
      menuTooltipContentLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.menu.menuTooltipContentLabel`
      )}
      menuTooltipLinkLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.menu.menuTooltipLinkLabel`
      )}
      conversionRateTooltip={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateTooltip`
      )}
      conversionRateLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateLabel`
      )}
      averageTooltip={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageTooltip`)}
      averageLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageLabel`)}
      liquidCollectiveTooltip={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveTooltip`
      )}
      liquidCollectiveLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveLabel`
      )}
      lastOracleRunLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.lastOracleRunLabel`
      )}
      ethSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
      lsEthSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
      liquidCollectiveSidebarLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.sidebar.liquidCollectiveSidebarLabel`
      )}
      etherscanLabel={t(`${GENERIC_TRANSLATIONS_KEY}.sidebar.etherscanLabel`)}
      duneLabel={t(`${GENERIC_TRANSLATIONS_KEY}.sidebar.duneLabel`)}
      protocolMetricsLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.sidebar.protocolMetricsLabel`
      )}
      items={menu.map((routeInfo) => ({
        label: t(`${GENERIC_TRANSLATIONS_KEY}.${routeInfo.label}`),
        route: routeInfo.route,
      }))}
      walletBalanceLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.accountInfo.walletBalanceLabel`
      )}
      stakedBalanceLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.accountInfo.stakedBalanceLabel`
      )}
      disconnectButtonLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.accountInfo.disconnectButtonLabel`
      )}>
      <RewardsChart
        routes={AppRoutes}
        label={t(`${REWARDS_PAGE_TRANSLATIONS_KEY}.rewardsChart.label`)}
        ethLabel={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
        emptyStateTitle={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.rewardsChart.emptyStateTitle`
        )}
        emptyStateSubtitle={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.rewardsChart.emptyStateSubtitle`
        )}
        emptyStateButton={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.rewardsChart.emptyStateButton`
        )}
        exportLabel={t(`${GENERIC_TRANSLATIONS_KEY}.exportLabel`)}
      />
      <WalletCardGroup
        routes={AppRoutes}
        cards={[
          UserCardTypes.balanceETH,
          UserCardTypes.balanceLsETH,
          UserCardTypes.feesPaid,
          UserCardTypes.SRR,
        ]}
        ethLabel={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
        lsEthLabel={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
        headline={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.walletCardGroup.headline`
        )}
        balanceEthLabel={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.walletCardGroup.balanceEthLabel`
        )}
        balanceEthButtonLabel={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.walletCardGroup.balanceEthButtonLabel`
        )}
        balanceLsEthLabel={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.walletCardGroup.balanceLsEthLabel`
        )}
        balanceLsEthButtonLabel={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.walletCardGroup.balanceLsEthButtonLabel`
        )}
        feesPaidLabel={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.walletCardGroup.feesPaidLabel`
        )}
        srrLabel={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.walletCardGroup.srrLabel`
        )}
        srrValueLabel={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.walletCardGroup.srrValueLabel`
        )}
        srrTooltipLabel={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.walletCardGroup.srrTooltipLabel`
        )}
      />
      <RewardsTransactions
        routes={AppRoutes}
        headline={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.rewardsTransactions.headline`
        )}
        viewAllButtonLabel={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.rewardsTransactions.viewAllButtonLabel`
        )}
        dateTimeLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.dateTimeLabel`
        )}
        typeLabel={t(`${GENERIC_TRANSLATIONS_KEY}.transactionsView.typeLabel`)}
        ethSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
        lsEthSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
        amountLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.amountLabel`
        )}
        conversionRateLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.conversionRateLabel`
        )}
        transactionIdLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.transactionIdLabel`
        )}
        mobileTransactionTitleLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.mobileTransactionTitleLabel`
        )}
        mobileTransactionButtonLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.mobileTransactionButtonLabel`
        )}
      />
      <MobileAccordionMetrics
        caption={t(`${REWARDS_PAGE_TRANSLATIONS_KEY}.accordionMetrics.caption`)}
        headline={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.accordionMetrics.headline`
        )}
        ethStakedLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.ethStakedLabel`)}
        lsEthSupplyLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.lsEthSupplyLabel`
        )}
        conversionRateTooltip={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateTooltip`
        )}
        conversionRateLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateLabel`
        )}
        averageTooltip={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageTooltip`)}
        averageLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageLabel`)}
        liquidCollectiveTooltip={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveTooltip`
        )}
        liquidCollectiveLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveLabel`
        )}
        lastOracleRunLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.lastOracleRunLabel`
        )}
        ethSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
        lsEthSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
      />
      <FAQ
        label={t(`${GENERIC_TRANSLATIONS_KEY}.faq.label`)}
        items={[
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.WHAT_IS_LSETH.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.WHAT_IS_LSETH.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.PROTOCOL_RATE.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.PROTOCOL_RATE.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.PROTOCOL_SERVICE_FEE.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.PROTOCOL_SERVICE_FEE.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.VALUE_DIFFERENCE.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.VALUE_DIFFERENCE.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.LIQUID_COLLECTIVE.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.LIQUID_COLLECTIVE.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.WITHDRAWAL_TIME.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.WITHDRAWAL_TIME.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.HOW_REDEMPTIONS_WORK.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.HOW_REDEMPTIONS_WORK.content}`
            ),
          },
        ]}
      />
    </Layout>
  );
};
