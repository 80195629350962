import {
  REWARDS_PAGE_TRANSLATIONS_KEY,
  GENERIC_TRANSLATIONS_KEY,
  PENDING_REDEEM_PAGE_TRANSLATIONS_KEY,
  menu,
  AppRoutes,
} from '../constants.ts';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  MobileAccordionMetrics,
  FAQ,
  PendingRedemptions,
  FAQItems,
} from '@alluvial/ui-components/components';

export const PendingPage = () => {
  const { t } = useTranslation();

  return (
    <Layout
      routes={AppRoutes}
      ethStakedLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.ethStakedLabel`)}
      lsEthSupplyLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.lsEthSupplyLabel`
      )}
      menuTooltipContentLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.menu.menuTooltipContentLabel`
      )}
      menuTooltipLinkLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.menu.menuTooltipLinkLabel`
      )}
      conversionRateTooltip={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateTooltip`
      )}
      conversionRateLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateLabel`
      )}
      averageTooltip={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageTooltip`)}
      averageLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageLabel`)}
      liquidCollectiveTooltip={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveTooltip`
      )}
      liquidCollectiveLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveLabel`
      )}
      lastOracleRunLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.lastOracleRunLabel`
      )}
      ethSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
      lsEthSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
      liquidCollectiveSidebarLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.sidebar.liquidCollectiveSidebarLabel`
      )}
      etherscanLabel={t(`${GENERIC_TRANSLATIONS_KEY}.sidebar.etherscanLabel`)}
      duneLabel={t(`${GENERIC_TRANSLATIONS_KEY}.sidebar.duneLabel`)}
      protocolMetricsLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.sidebar.protocolMetricsLabel`
      )}
      items={menu.map((routeInfo) => ({
        label: t(`${GENERIC_TRANSLATIONS_KEY}.${routeInfo.label}`),
        route: routeInfo.route,
      }))}
      walletBalanceLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.accountInfo.walletBalanceLabel`
      )}
      stakedBalanceLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.accountInfo.stakedBalanceLabel`
      )}
      disconnectButtonLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.accountInfo.disconnectButtonLabel`
      )}>
      <PendingRedemptions
        ethSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
        lsEthSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
        headline={t(`${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.headline`)}
        withdrawalsTooltip={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.withdrawalsTooltip`
        )}
        withdrawalsCaption={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.withdrawalsCaption`
        )}
        claimsLabel={t(`${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.claimsLabel`)}
        selectAllLabel={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.selectAllLabel`
        )}
        claimSelectedButtonLabel={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.claimSelectedButtonLabel`
        )}
        signTransactionInWalletButtonLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.signButtons.signTransactionInWalletButtonLabel`
        )}
        signTransactionButtonLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.signButtons.signTransactionButtonLabel`
        )}
        cancelButtonLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.signButtons.cancelButtonLabel`
        )}
        claimModalTitle={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.claimModalTitle`
        )}
        claimModalTooltipLabel={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.claimModalTooltipLabel`
        )}
        redeemLabel={t(`${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.redeemLabel`)}
        maxTransactionCostCaption={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.maxTransactionCostCaption`
        )}
        claimButtonLabel={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.claimButtonLabel`
        )}
        requestedLabel={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.requestedLabel`
        )}
        redeemedLabel={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.redeemedLabel`
        )}
        forLabel={t(`${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.forLabel`)}
        redeemableLabel={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.redeemableLabel`
        )}
        processingLabel={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.processingLabel`
        )}
        globalErrorLabel={t(`${GENERIC_TRANSLATIONS_KEY}.globalErrorLabel`)}
        tooltipInfoLabel={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.tooltipInfoLabel`
        )}
        learnMoreLabel={t(
          `${PENDING_REDEEM_PAGE_TRANSLATIONS_KEY}.learnMoreLabel`
        )}
        contractAddressLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.contractAddressLabel`
        )}
        contractAddressTooltip={t(
          `${GENERIC_TRANSLATIONS_KEY}.contractAddressTooltip`
        )}
      />
      <MobileAccordionMetrics
        caption={t(`${REWARDS_PAGE_TRANSLATIONS_KEY}.accordionMetrics.caption`)}
        headline={t(
          `${REWARDS_PAGE_TRANSLATIONS_KEY}.accordionMetrics.headline`
        )}
        ethStakedLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.ethStakedLabel`)}
        lsEthSupplyLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.lsEthSupplyLabel`
        )}
        conversionRateTooltip={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateTooltip`
        )}
        conversionRateLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateLabel`
        )}
        averageTooltip={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageTooltip`)}
        averageLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageLabel`)}
        liquidCollectiveTooltip={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveTooltip`
        )}
        liquidCollectiveLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveLabel`
        )}
        lastOracleRunLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.metrics.lastOracleRunLabel`
        )}
        ethSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
        lsEthSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
      />
      <FAQ
        label={t(`${GENERIC_TRANSLATIONS_KEY}.faq.label`)}
        items={[
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.PROTOCOL_RATE.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.PROTOCOL_RATE.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.WITHDRAWAL_TIME.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.WITHDRAWAL_TIME.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.HOW_REDEMPTIONS_WORK.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.HOW_REDEMPTIONS_WORK.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.PROTOCOL_SERVICE_FEE.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.PROTOCOL_SERVICE_FEE.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.VALUE_DIFFERENCE.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.VALUE_DIFFERENCE.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.LIQUID_COLLECTIVE.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.LIQUID_COLLECTIVE.content}`
            ),
          },
          {
            title: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.WHAT_IS_LSETH.title}`
            ),
            content: t(
              `${GENERIC_TRANSLATIONS_KEY}.faq.${FAQItems.WHAT_IS_LSETH.content}`
            ),
          },
        ]}
      />
    </Layout>
  );
};
