import {
  AppRoutes,
  GENERIC_TRANSLATIONS_KEY,
  menu,
  TRANSACTION_PAGE_TRANSLATIONS_KEY,
} from '../constants.ts';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  HistoryTransactions,
  HistoricalTotals,
  PendingTransactions,
} from '@alluvial/ui-components/components';

export const TransactionsPage = () => {
  const { t } = useTranslation();

  return (
    <Layout
      routes={AppRoutes}
      isAllowed={true}
      ethStakedLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.ethStakedLabel`)}
      lsEthSupplyLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.lsEthSupplyLabel`
      )}
      menuTooltipContentLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.menu.menuTooltipContentLabel`
      )}
      menuTooltipLinkLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.menu.menuTooltipLinkLabel`
      )}
      conversionRateTooltip={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateTooltip`
      )}
      conversionRateLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.conversionRateLabel`
      )}
      averageTooltip={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageTooltip`)}
      averageLabel={t(`${GENERIC_TRANSLATIONS_KEY}.metrics.averageLabel`)}
      liquidCollectiveTooltip={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveTooltip`
      )}
      liquidCollectiveLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.liquidCollectiveLabel`
      )}
      lastOracleRunLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.metrics.lastOracleRunLabel`
      )}
      ethSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
      lsEthSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
      liquidCollectiveSidebarLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.sidebar.liquidCollectiveSidebarLabel`
      )}
      etherscanLabel={t(`${GENERIC_TRANSLATIONS_KEY}.sidebar.etherscanLabel`)}
      duneLabel={t(`${GENERIC_TRANSLATIONS_KEY}.sidebar.duneLabel`)}
      protocolMetricsLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.sidebar.protocolMetricsLabel`
      )}
      items={menu.map((routeInfo) => ({
        label: t(`${GENERIC_TRANSLATIONS_KEY}.${routeInfo.label}`),
        route: routeInfo.route,
      }))}
      walletBalanceLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.accountInfo.walletBalanceLabel`
      )}
      stakedBalanceLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.accountInfo.stakedBalanceLabel`
      )}
      disconnectButtonLabel={t(
        `${GENERIC_TRANSLATIONS_KEY}.accountInfo.disconnectButtonLabel`
      )}
      disableRightSidebar>
      <HistoricalTotals
        headline={t(`${TRANSACTION_PAGE_TRANSLATIONS_KEY}.headline`)}
        ethLabel={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
        totalStakedLabel={t(
          `${TRANSACTION_PAGE_TRANSLATIONS_KEY}.totalStakedLabel`
        )}
        totalRedeemedLabel={t(
          `${TRANSACTION_PAGE_TRANSLATIONS_KEY}.totalRedeemedLabel`
        )}
      />
      <PendingTransactions
        headline={t(`${TRANSACTION_PAGE_TRANSLATIONS_KEY}.pendingHeadline`)}
        dateTimeLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.dateTimeLabel`
        )}
        typeLabel={t(`${GENERIC_TRANSLATIONS_KEY}.transactionsView.typeLabel`)}
        ethSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
        lsEthSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
        amountLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.amountLabel`
        )}
        conversionRateLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.conversionRateLabel`
        )}
        transactionIdLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.transactionIdLabel`
        )}
        mobileTransactionTitleLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.mobileTransactionTitleLabel`
        )}
        mobileTransactionButtonLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.mobileTransactionButtonLabel`
        )}
      />
      <HistoryTransactions
        headline="History"
        dateTimeLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.dateTimeLabel`
        )}
        typeLabel={t(`${GENERIC_TRANSLATIONS_KEY}.transactionsView.typeLabel`)}
        ethSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.ethSymbol`)}
        lsEthSymbol={t(`${GENERIC_TRANSLATIONS_KEY}.lsEthSymbol`)}
        amountLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.amountLabel`
        )}
        conversionRateLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.conversionRateLabel`
        )}
        transactionIdLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.transactionIdLabel`
        )}
        mobileTransactionTitleLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.mobileTransactionTitleLabel`
        )}
        mobileTransactionButtonLabel={t(
          `${GENERIC_TRANSLATIONS_KEY}.transactionsView.mobileTransactionButtonLabel`
        )}
        exportLabel={t(`${GENERIC_TRANSLATIONS_KEY}.exportLabel`)}
        filterItems={[
          {
            label: t(
              `${TRANSACTION_PAGE_TRANSLATIONS_KEY}.historyTransactions.pastWeekLabel`
            ),
            value: '1W',
            type: 'date',
          },
          {
            label: t(
              `${TRANSACTION_PAGE_TRANSLATIONS_KEY}.historyTransactions.pastMonthLabel`
            ),
            value: '1M',
            type: 'date',
          },
          {
            label: t(
              `${TRANSACTION_PAGE_TRANSLATIONS_KEY}.historyTransactions.pastSixMonthsLabel`
            ),
            value: '6M',
            type: 'date',
          },
          {
            label: t(
              `${TRANSACTION_PAGE_TRANSLATIONS_KEY}.historyTransactions.pastYearLabel`
            ),
            value: '1Y',
            type: 'date',
          },
          {
            label: t(
              `${TRANSACTION_PAGE_TRANSLATIONS_KEY}.historyTransactions.allTimeLabel`
            ),
            value: 'All',
            type: 'date',
            isDefaultValue: true,
          },
        ]}
      />
    </Layout>
  );
};
