import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { holesky, mainnet } from 'wagmi/chains';
import { Config, http } from 'wagmi';
import {
  coinbaseWallet,
  walletConnectWallet,
  metaMaskWallet,
  ledgerWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { customRPC } from 'src/models/CustomRPC';

const wallets = [
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet,
      walletConnectWallet,
      coinbaseWallet,
      ledgerWallet,
    ],
  },
];

const configRPC: Config = getDefaultConfig({
  appName: import.meta.env.VITE_APP_NAME || 'Liquid Collective',
    appDescription: import.meta.env.VITE_APP_DESCRIPTION || 'Liquid Collective Staking App',
  appIcon:
    'https://liquidcollective.io/images/lseth/png/LsETH-receipt-token-circle.png',
  projectId: import.meta.env.VITE_WALLETCONNECT_PROJECT_ID || 'YOUR_PROJECT_ID',
  chains: import.meta.env.VITE_NETWORK ? [mainnet] : [holesky],
  transports: {
    [mainnet.id]: http(customRPC),
  },
  wallets,
});

const defaultConfig: Config = getDefaultConfig({
  appName: import.meta.env.VITE_APP_NAME || 'Liquid Collective',
    appDescription: import.meta.env.VITE_APP_DESCRIPTION || 'Liquid Collective Staking App',
  appIcon:
    'https://liquidcollective.io/images/lseth/png/LsETH-receipt-token-circle.png',
  projectId: import.meta.env.VITE_WALLETCONNECT_PROJECT_ID || 'YOUR_PROJECT_ID',
  chains: import.meta.env.VITE_NETWORK === 'mainnet' ? [mainnet] : [holesky],
  wallets,
});

export const config = customRPC ? configRPC : defaultConfig;
