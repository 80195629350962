import { useTranslation } from 'react-i18next';
import {
  TermsAndConditionsModal,
  HomePageSection,
} from '@alluvial/ui-components/components';
import { AppRoutes } from '../constants.ts';

export const HomePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <HomePageSection
        ethLabel={t('generic.ethSymbol')}
        announcementLabel={t('homePage.announcementLabel')}
        announcementLinkLabel={t('homePage.announcementLinkLabel')}
        connectWalletLabel={t('homePage.connectWalletLabel')}
        headline={t('homePage.headline')}
        description={t('homePage.description')}
        connectButtonLabel={t('homePage.connectWalletLabel')}
        list={[
          { text: t('homePage.list.firstPoint') },
          { text: t('homePage.list.secondPoint') },
          { text: t('homePage.list.thirdPoint') },
          { text: t('homePage.list.fourthPoint') },
        ]}
        docsLabel={t('homePage.footer.docsLabel')}
        liquidcollectiveLabel={t('homePage.footer.liquidcollectiveLabel')}
      />
      <TermsAndConditionsModal
        routes={AppRoutes}
        title={t('homePage.termsAndConditions.title')}
        termsAndConditionsLabel={t(
          'homePage.termsAndConditions.termsAndConditionsLabel'
        )}
        termsAndConditionsLink={t(
          'homePage.termsAndConditions.termsAndConditionsLink'
        )}
        userAgreementLabel={t('homePage.termsAndConditions.userAgreementLabel')}
        userAgreementLink={t('homePage.termsAndConditions.userAgreementLink')}
        checkboxLabel={t('homePage.termsAndConditions.checkboxLabel')}
        acceptButtonLabel={t('homePage.termsAndConditions.acceptButtonLabel')}
        rejectButtonLabel={t('homePage.termsAndConditions.rejectButtonLabel')}
      />
    </>
  );
};
