import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { StakePage } from './pages/StakePage.tsx';
import { HomePage } from './pages/HomePage.tsx';
import { TransactionsPage } from './pages/TransactionsPage.tsx';
import { RedeemPage } from './pages/RedeemPage.tsx';
import { PendingPage } from './pages/PendingPage.tsx';
import { RewardsPage } from './pages/RewardsPage.tsx';
import './i18n';
import { AppRoutes, termsAndServiceRoute } from './constants.ts';
import { TermsAndServicePage } from './pages/TermsAndServicePage/TermsAndServicePage.tsx';

function App() {
  return (
    <Router>
      <Routes>
        <Route path={AppRoutes.home} element={<HomePage />} />
        <Route path={termsAndServiceRoute} element={<TermsAndServicePage />} />
        <Route path={AppRoutes.rewards} element={<RewardsPage />} />
        <Route path={AppRoutes.stake} element={<StakePage />} />
        <Route path={AppRoutes.redeem} element={<RedeemPage />} />
        <Route path={AppRoutes.redeemPending} element={<PendingPage />} />
        <Route path={AppRoutes.transactions} element={<TransactionsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
